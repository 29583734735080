<template>
  <div v-if="breadcrumbs" class="breadcrumb">
    <ol class="breadcrumb">
      <li
        v-for="(breadcrumb, idx) in breadcrumbs"
        :key="idx"
        class="breadcrumb-item"
        :class="{ active: !breadcrumb.route }"
      >
        <router-link v-if="breadcrumb.route" :to="breadcrumb.route">
          {{ breadcrumb.name }}
        </router-link>
        <span class="active" v-else>{{ breadcrumb.name }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
};
</script>
