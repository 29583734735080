/**
 *
 */
export const yesNo = () => {
  return ['YES', 'NO'];
};

export const projectState = () => {
  return ['ACTIVE', 'FINISHED'];
};
