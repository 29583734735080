<template>
  <div class="button-wait">
    <b-button class="btn-sm" variant="bg" @click="onStart">
      <div class="button-text"><slot name="buttonText">Wait</slot></div>
      <Spinner class="" :size="20" v-if="buildState === 'IN_PROGRESS'" />
    </b-button>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

/**
 * A button which is continously polling a provided callback functions
 */
export default {
  components: { Spinner },
  props: {
    /**
     * Provide a function which is called once and starts polling
     */
    startCallback: Function,
    /**
     * Provide a function which is continiously called (once per second)
     * until 'FINISHED' is returned or timeout is reached
     */
    pollCallback: Function,
  },
  data() {
    return {
      pollBuildState: null,
      buildState: 'NOT_STARTED',
      buildTimer: 0,
      timeoutInSeconds: 120,
      intervalInMilliseconds: 2000,
    };
  },
  methods: {
    async onStart() {
      this.buildState = 'IN_PROGRESS';
      await this.startCallback();

      /** poll the build state until finished */
      this.pollBuildState = setInterval(async () => {
        this.buildState = await this.pollCallback();
        // console.log('pollBuildState', this.buildState, this.buildTimer);

        /** Stop polling when wait is finished */
        if (this.buildState === 'FINISHED') {
          clearInterval(this.pollBuildState);
          this.buildTimer = 0;
        }
        /* stop polling when timeout is reached */
        if (this.buildTimer > this.timeoutInSeconds) {
          this.buildTimer = 0;
          this.buildState = 'NOT_STARTED';
          clearInterval(this.pollBuildState);
        }
        this.buildTimer++;
      }, this.intervalInMilliseconds);
    },
  },
};
</script>

<style scoped lang="scss">
.button-wait {
  position: relative;
  display: inline-block;
}
.button-wait button {
  vertical-align: top;
  padding: 0;
  padding-right: 0.9rem;
}
.button-wait .button-text {
  display: inline-block;
  line-height: 25px;
  padding: 5px 0 5px 10px;
}

.button-wait .spinner {
  position: relative;
  left: 0.5rem;
  top: 5px;
}
</style>
